<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky" >
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container" style="max-width: 100%;background-color: #aa6f1b;color: white;" >
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact" style="color: white;">
            <ul style="color: white;">
              <li style="color: white;">{{'Welcome to the International Islamic Center'|translate}}</li>
              <li *ngIf="ContactUsList && ContactUsList.length > 0" style="color: white;"><i class="fa fa-phone" aria-hidden="true"></i>{{'Call Us'|translate}}:{{ContactUsList[0].phone}}</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 text-end">
          <!-- <div>
            <app-settings></app-settings>
          </div> -->
       
          <ul class="header-dropdown">
            <!-- <li class="compare">
              <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>Compare</a>
            </li> -->
            <li class="mobile-wishlist" style="margin-left: 10px;margin-right: 10px;color: white;" >
              <a href="https://admin.alazhar-iicpsr.org" style="color: white;"><i class="fa fa-user"style="color: white;" aria-hidden="true" ></i>{{'Login'|translate}}</a>
            </li>
          

            <li class="onhover-dropdown mobile-account">
              <i class="fa fa-language" aria-hidden="true" style="color: white;"></i>
              <a [routerLink]="['/']" style="color: white;">{{'Language'|translate}}</a>
              
              <ul class="onhover-show-div">
                <li *ngFor="let language of languages" style="color: white;">
                  <a href="javascript:void(0)" (click)="changeLanguage(language.code)" >{{ language.name |translate}}</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container"  >
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <app-left-menu></app-left-menu>
            <div class="brand-logo">
              <a routerLink="/home/fashion" id="headerlogo">
                <img style=" max-width: 100px;" [src]="themeLogo" dir="ltr" class="img-fluid" alt="logo">
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div [ngStyle]="{'margin-left':lang === 'ar-EG' ? '80px' : '0' }">
              <app-menu></app-menu>
            </div>
            <div>
              <ul>
                <li class="onhover-div mobile-setting">
                  <i class="gg-dark-mode" aria-hidden="true" (click)="customizeLayoutDark()"></i>
                </li>
              
              </ul>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
