import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { ActivityTypeService } from '../../../Islamic-Center/Activites/ActivityType/activity-type.service';
import { ActivityTypeModel } from '../../../Islamic-Center/Activites/ActivityType/ActivityTypeModel';
import { ARTUnitsService } from '../../../Islamic-Center/ART-Units/art-units.service';
import { ARTUnitsModel } from '../../../Islamic-Center/ART-Units/ARTUnitsModel';
import { PublicationTypeService}from '../../../Islamic-Center/Publications/Publication-Type/publication-type.service';
import { PublicationTypeModel } from 'src/app/Islamic-Center/Publications/Publication-Type/PublicationTypeModel';
import { CenterLibraryTypeModel } from 'src/app/Islamic-Center/Center-Library/Center-Library-Type/CenterLibraryTypeModel';
import { CenterLibraryTypeService } from 'src/app/Islamic-Center/Center-Library/Center-Library-Type/center-library-type.service';
import { ResearchTypeService } from 'src/app/Islamic-Center/Research-Ethics/ResearchType/research-type.service';
import {ResearchTypeModel  } from 'src/app/Islamic-Center/Research-Ethics/ResearchType/research-type.model';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  units:ARTUnitsModel[]=[];
  researchTypes:ResearchTypeModel[]=[];
  Publication:PublicationTypeModel[]=[];
  public menuItems: Menu[];
  types:ActivityTypeModel[]=[];
  LibraryType:CenterLibraryTypeModel[]=[];
  constructor(private router: Router, 
    public navServices: NavService,
    private ActivityTypeService:ActivityTypeService,
    private ARTUnitsService:ARTUnitsService,
    private PublicationTypeService:PublicationTypeService,
    private CenterLibraryService:CenterLibraryTypeService,
    private ResearchTypeService : ResearchTypeService
    ) {
    this.navServices.items.subscribe(menuItems => this.menuItems = menuItems );
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
    this.lang= localStorage.getItem('theme_lang');
 
  }
  lang = '';
  ngOnInit(): void {
    debugger
    this.ResearchTypeService.getAll()
    .subscribe(result =>{
      this.researchTypes = result;
      var index = this.menuItems.findIndex(x=>x.title == 'Research ethics');
      if(this.researchTypes && this.researchTypes.length>0 ){
        this.menuItems[index].children=[];
        this.researchTypes.forEach(element => {
          if(index>-1){
            if(this.lang == 'ar-EG'){
              var item = { path: '/islamic-center/researchs/'+element.id, title: element.nameAr , type: 'link' }
              this.menuItems[index].children.push(item);
            }
            else{
              var item = { path: '/islamic-center/researchs/'+element.id, title: element.nameEn , type: 'link' }
              this.menuItems[index].children.push(item);

            }
          }
        });
       
      }
    })
    this.ActivityTypeService.getAll()
    .subscribe(result =>{
      this.types = result;
      var index = this.menuItems.findIndex(x=>x.title == 'Activities');
      if(this.types && this.types.length>0 ){
        this.menuItems[index].children=[];
        this.types.forEach(element => {
          if(index>-1){
            if(this.lang == 'ar-EG'){
              var item = { path: '/islamic-center/activites/'+element.id, title: element.nameAr , type: 'link' }
              this.menuItems[index].children.push(item);
            }
            else{
              var item = { path: '/islamic-center/activites/'+element.id, title: element.nameEn , type: 'link' }
              this.menuItems[index].children.push(item);

            }
          }
        });
       
      }
    })
    this.ARTUnitsService.getAll()
    .subscribe(result=>{
      this.units = result;
      var index = this.menuItems.findIndex(x=>x.title == 'ART unit');
      if(this.units && this.units.length>0 ){
        this.menuItems[index].children=[];
        this.units.forEach(element => {
          if(index>-1){
            if(this.lang == 'ar-EG'){
              var item = { path: '/islamic-center/workshops/'+element.id, title: element.nameAr , type: 'link' }
              this.menuItems[index].children.push(item);
            }
            else{
              var item = { path: '/islamic-center/workshops/'+element.id, title: element.nameEn , type: 'link' }
              this.menuItems[index].children.push(item);

            }
          }
        });
       
      }
    })
    this.PublicationTypeService.getAll()
    .subscribe(result=>{
      this.Publication = result;
      var index = this.menuItems.findIndex(x=>x.title == 'Publications');
      if(this.Publication && this.Publication.length>0 ){
        this.menuItems[index].children=[];
        this.Publication.forEach(element => {
          if(index>-1){
            if(this.lang == 'ar-EG'){
              var item = { path: '/islamic-center/publications/'+element.id, title: element.nameAr , type: 'link' }
              this.menuItems[index].children.push(item);
            }
            else{
              var item = { path: '/islamic-center/publications/'+element.id, title: element.nameEn , type: 'link' }
              this.menuItems[index].children.push(item);
            }
          }
        });
      }
    })
    this.CenterLibraryService.getAll()
    .subscribe(result=>{
      this.LibraryType = result;
      var index = this.menuItems.findIndex(x=>x.title == 'Center Library');
      if(this.LibraryType && this.LibraryType.length>0 ){
        this.menuItems[index].children=[];
        this.LibraryType.forEach(element => {
          if(index>-1){
            if(this.lang == 'ar-EG'){
              var item = { path: '/islamic-center/library/'+element.id, title: element.nameAr , type: 'link' }
              this.menuItems[index].children.push(item);
            }
            else{
              var item = { path: '/islamic-center/library/'+element.id, title: element.nameEn , type: 'link' }
              this.menuItems[index].children.push(item);
            }
          }
        });
      }
    })
  }


  
  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

}
