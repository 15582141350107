<!--component html goes here -->



<div >
    <div class="img-wrapper">
      <!-- <div class="lable-block">
        <span class="lable3" *ngIf="product.new">new</span>
        <span class="lable4" *ngIf="product.sale">on sale</span>
      </div> -->
      <div class="front">
        <a routerLink="/islamic-center/workshop-details/{{workshope.id}}" >
          <img 
          style="height: 200px;"
            class="img-fluid lazy-loading" 
            src="{{ workshope.imageURl }}" />
        </a>
      </div>
      <div class="back" *ngIf="onHowerChangeImage">
        <a routerLink="/islamic-center/workshop-details/{{workshope.id}}">
          <img style="height: 200px;" [src]="workshope.imageURl" class="img-fluid lazy-loading">
        </a>
      </div>
      <!-- <ul class="product-thumb-list" *ngIf="thumbnail">
        <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.images">
          <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
            <img [lazyLoad]="image.src">
          </a>
        </li>
      </ul> -->
      <!-- <div class="cart-info cart-wrap">
        <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)"
          *ngIf="cartModal">
          <i class="ti-shopping-cart"></i>
        </a>
        <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)"
          *ngIf="!cartModal">
          <i class="ti-shopping-cart"></i>
        </a>
        <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
          <i class="ti-heart" aria-hidden="true"></i>
        </a>
        <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
          <i class="ti-search" aria-hidden="true"></i>
        </a>
        <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
          <i class="ti-reload" aria-hidden="true"></i>
        </a>
      </div> -->
    </div>
    <!-- <div class="blog-details">
      <h4>{{"15/12/2024"|translate }}</h4>
        <p>{{lang==='ar-EG'?workshope.titleAr:workshope.titleEn}}</p>
      <p style="height: 50px;overflow: hidden; width: 200px;">{{lang==='ar-EG'?workshope.descriptionAr:workshope.descriptionEn}}</p>
      <hr class="style1">
    </div> -->
    <div class="product-detail">
      <div>
        <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
        <a style="padding-top: 15px;">
          <h6 style="font-weight: bold;">{{workshope.date|date:'dd/MM/yyyy' }}</h6>
          <h6 routerLink="/islamic-center/workshop-details/{{workshope.id}}">{{lang==='ar-EG'?workshope.titleAr:workshope.titleEn}}</h6>
        </a>
        <p>{{lang==='ar-EG'?workshope.descriptionAr:workshope.descriptionEn}}</p>
      </div>
    </div>
  </div>
  <!-- <app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box> -->
 
  
  