import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkshopRegistrationsService } from './workshop-registrations.service';
import { WorkshopRegistrationsModel } from './workshop-registrations.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
    selector: 'regrster-modal',
    templateUrl: 'regrster-modal.component.html',
    styleUrls: ['regrster-modal.component.scss']
})
export class RegrsterModalComponent implements OnInit  {
    declare lang: any;
    @Input() workshopId: number;
    @ViewChild("Regrster", { static: false }) Regrster: TemplateRef<any>;
    model:WorkshopRegistrationsModel={};
    public closeResult: string;
  
    public modalOpen: boolean = false;
    form:FormGroup;
    constructor(@Inject(PLATFORM_ID) private platformId: Object,
      private router: Router, private modalService: NgbModal,
      private WorkshopRegistrationsService:WorkshopRegistrationsService,
      public fb:FormBuilder
      ) { }
      ngOnInit(): void {
    }  
    openModal() {
        this.modalOpen = true;
        this.model.workshopId = this.workshopId;
        this.form = this.createForm();
        if (isPlatformBrowser(this.platformId)) { // For SSR 
          this.modalService.open(this.Regrster, { 
            size: 'lg',
            ariaLabelledBy: 'modal-basic-title',
            centered: true,
            windowClass: 'Quickview' 
          }).result.then((result) => {
            `Result ${result}`
          }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
        }
      }
      private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return `with: ${reason}`;
        }
      }
      createForm():FormGroup{
        return this.fb.group({
            id:[this.model.id],
            workshopId:[this.model.workshopId,Validators.required],
            email:[this.model.email,Validators.required],
            name:[this.model.name,Validators.required],
            phone:[this.model.phone,Validators.required],
            description:[this.model.description],
        })
      }
      submit(){
        debugger;
        this.model = this.form.getRawValue();
        this.model.id = 0;
        this.WorkshopRegistrationsService.create(this.model)
        .subscribe(result=>{
            if(result == true){
                this.model = new WorkshopRegistrationsModel()
                this.form = this.createForm();
                this.modalService.dismissAll();
            }
        })
      }
}
