<div >
    <div class="img-wrapper">
      <div class="front">
        <a routerLink="/islamic-center/activity-details/{{Activity.id}}">
          <img 
          style="height: 200px;"
            class="img-fluid lazy-loading" 
            src="{{ Activity.imageURl }}" />
        </a>
      </div>
      <div class="back" *ngIf="onHowerChangeImage">
        <a routerLink="/islamic-center/activity-details/{{Activity.id}}">
          <img style=" height: 200px;" [src]="Activity.imageURl" class="img-fluid lazy-loading">
        </a>
      </div>
    </div>
    <div class="blog-details">
        <h4>{{Activity.date|date:'dd/MM/yyyy' }}</h4>
        <a routerLink="/islamic-center/activity-details/{{Activity.id}}">
          <p>{{lang==='ar-EG'?Activity.titleAr:Activity.titleEn}}</p>
        </a>
        <p style="height: 50px;overflow: hidden;" *ngIf="lang==='ar-EG'" [innerHTML]="Activity.descriptionAr"></p>
        <p style="height: 50px;overflow: hidden;" *ngIf="lang!='ar-EG'" [innerHTML]="Activity.descriptionEn"></p>
        <hr class="style1">
      </div>
    <!-- <div class="product-detail">
      <div>
        <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
        <a style="padding-top: 15px;">
          <h6>{{ workshope.titleEn | titlecase }}</h6>
          <h6>{{lang==='ar-EG'?Activity.titleAr:Activity.titleEn}}</h6>
        </a>
        <p>{{lang==='ar-EG'?Activity.descriptionAr:Activity.descriptionEn}}</p>
      </div>
    </div> -->
  </div>
  <!-- <app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box> -->
 
  
  