import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { WorkshopRegistrationsModel } from './workshop-registrations.model';
@Injectable({
  providedIn: 'root'
})
export class WorkshopRegistrationsService {

  
  constructor(private http: HttpClient ) { }
  baseUrl = environment.baseApiUrl;
  token = localStorage.getItem("token");
  httpOptions = {
   headers: new HttpHeaders({
     // 'Content-Type': 'application/json; charset=utf-8',
     // 'Authorization': "Bearer " + token,
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
   })
 };

create(model:WorkshopRegistrationsModel): Observable<any> {
  return this.http
    .post<any>(`${this.baseUrl}WorkshopRegistrations/Insert`,model,this.httpOptions)
    .pipe(catchError((error: any) => throwError(error => error)));
}





}
