import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { WorkshopsService } from '../workshops.service';
import { WorkshopsModel } from '../WorkshopsModel';
import  {getThemeLang } from 'src/app/utils/util'
import { LangService } from 'src/app/shared/lang.service';
@Component({
    // moduleId: module.id,
    selector: 'workshops',
    templateUrl: 'workshops.component.html',
    styleUrls: ['workshops.component.scss']
})
export class WorkshopsComponent implements OnInit {

    @Input() product: Product;
    @Input() workshope: WorkshopsModel;
    // @Input() currency: any = this.productService.Currency; // Default Currency 
    @Input() thumbnail: boolean = false; // Default False 
    @Input() onHowerChangeImage: boolean = false; // Default False
    @Input() cartModal: boolean = false; // Default False
    @Input() loader: boolean = false;
    
    @ViewChild("quickView") QuickView: QuickViewComponent;
    @ViewChild("cartModal") CartModal: CartModalComponent;
  
    public ImageSrc : string
  public lang='';
    constructor(private modelService: WorkshopsService,public langService:LangService) {
        debugger;
        this.lang= localStorage.getItem('theme_lang');
     }
  
    ngOnInit(): void {
this.modelService.getAll().subscribe(data=>{
    this.WorkshopsList=data;
    //console.log("WorkshopsList",this.WorkshopsList)
})
      if(this.loader) {
        setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
      }
    }
  WorkshopsList:WorkshopsModel[]=[];
  modelObject:WorkshopsModel={};
    // Get Product Color
    Color(variants) {
      const uniqColor = [];
      for (let i = 0; i < Object.keys(variants).length; i++) {
        if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
          uniqColor.push(variants[i].color)
        }
      }
      return uniqColor
    }
  
    // Change Variants
    ChangeVariants(color, product) {
      product.variants.map((item) => {
        if (item.color === color) {
          product.images.map((img) => {
            if (img.image_id === item.image_id) {
              this.ImageSrc = img.src;
            }
          })
        }
      })
    }
  
    // Change Variants Image
    ChangeVariantsImage(src) {
      this.ImageSrc = src;
    }
  
    // addToCart(product: any) {
    //   this.modelService.addToCart(product);
    // }
  
    // addToWishlist(product: any) {
    //   this.modelService.addToWishlist(product);
    // }
  
    // addToCompare(product: any) {
    //   this.modelService.addToCompare(product);
    // }
  
  }
  