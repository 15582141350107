<!--component html goes here -->
<ng-template class="theme-modal" #quickView let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close float-end border-0" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
                <div class="col-lg-6  col-xs-12">
                    <div class="quick-view-img">
                        <img [src]="item.imageUrl" class="img-fluid " alt="">
                        <!-- <img [src]="ImageSrc ? ImageSrc : product.imageUrl" [alt]="product.imageUrl" class="img-fluid"> -->
                    </div>
                </div>
                <div class="col-lg-6 rtl-text">
                    <div class="product-right">
                        <h2>{{lang==='ar-EG'?item.nameAr:item.nameEn}}</h2>
                        <div class="border-product">
                            <h6 class="product-title">{{'specialization'|translate}}</h6>
                            <p *ngIf="lang==='ar-EG'" [innerHTML]="item.specializationAr"></p>
                            <p *ngIf="lang!='ar-EG'" [innerHTML]="item.specializationEn"></p>
                        </div>
                        <!-- <div class="border-product" *ngIf="item.date">
                            <h6 class="product-title">{{'date'|translate}}</h6>
                            <p>{{item.date | date:'dd/MM/y'}}</p>
                        </div> -->
                        <div class="product-buttons">
                            <a class="btn btn-solid" (click)="modal.dismiss('Cross click')">{{'Close'|translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>