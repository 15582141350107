<!--component html goes here -->
<div >
    <div class="img-wrapper">
      <div class="front">
        <a routerLink="/islamic-center/news-details/{{ News.id }}">
          <img 
          style="height: 200px;"
            class="img-fluid lazy-loading" 
            src="{{ News.imageUrl }}" />
        </a>
      </div>
      <div class="back" *ngIf="onHowerChangeImage">
        <a routerLink="/islamic-center/news-details/{{ News.id }}">
          <img style=" height: 200px;" [src]="News.imageUrl" class="img-fluid lazy-loading">
        </a>
      </div>
    </div>
    <div class="blog-details">
        <a routerLink="/islamic-center/news-details/{{ News.id }}">
          <p>{{lang==='ar-EG'?News.titleAr:News.titleEn}}</p>
        </a>
        <!-- <p style="height: 50px;overflow: hidden;" *ngIf="lang==='ar-EG'" [innerHTML]="News.descriptionAr"></p>
        <p style="height: 50px;overflow: hidden;" *ngIf="lang!='ar-EG'" [innerHTML]="News.descriptionEn"></p> -->
        <hr class="style1">
      </div>
  
  </div>
 
  
  