export class WorkshopRegistrationsModel{
    id?: number;
    workshopId?: number;
    name          ?: string;
    phone         ?: string;
    email         ?: string;
    description   ?: string;
    workshopNameAr?: string;
    workshopNameEn?: string;
    constructor(item?:WorkshopRegistrationsModel){
        item =item?item:{};
        this.id = item.id ? item.id : 0;
        this.workshopId = item.workshopId ? item.workshopId : 0;
        this.name           = item.name           ? item.name           : '';
        this.phone          = item.phone          ? item.phone          : '';
        this.email          = item.email          ? item.email          : '';
        this.description    = item.description    ? item.description    : '';
        this.workshopNameAr = item.workshopNameAr ? item.workshopNameAr : '';
        this.workshopNameEn = item.workshopNameEn ? item.workshopNameEn : '';
    }
}