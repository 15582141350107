<!--footer section -->
<footer [class]="class">
	<!-- <div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>KNOW IT ALL FIRST!</h4>
								<p>Never Miss Anything From Multikart By Signing Up To Our Newsletter. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Enter your email">
							</div>
							<button type="submit" class="btn btn-solid">subscribe</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div> -->
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-2 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant" >
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo" >
						</div>
						
						<div class="footer-social">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/iicpsr"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
								  <!-- <li>
                                    <a href="https://www.facebook.com/profile.php?id=100008102470493&mibextid=ZbWKwL"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li> -->
                                <li>
                                    <a href="https://www.youtube.com/user/IICPSR"><i class="fa fa-youtube" aria-hidden="true"></i></a>                 
                                </li>
                                <li>
                                    <a href="https://twitter.com/iicpsr"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <!-- <li>
                                    <a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li> -->
                                <!-- <li>
                                    <a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                </li> -->
                            </ul>
                        </div>
					</div>
				</div>
				<!-- <div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>{{'News'|translate}}</h4>
                        </div>
                        <div class="footer-contant">
							<div *ngFor="let item of FooterList">
								<p style="max-width: 60px;" [innerHTML]="lang==='ar-EG'?item.descriptionAr:item.descriptionEn"> </p>
							</div>
                        </div>
					</div>
				</div> -->
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>{{'Activites'|translate}}</h4>
                        </div>
                        <div class="footer-contant">
                            <ul *ngFor="let item of ActivityTypeList">
                                <li><a routerLink="/islamic-center/activites/{{item.id}}" href="javascript:void(0)">{{lang==='ar-EG'?item.nameAr:item.nameEn}}</a></li>
                                <!-- <li><a href="javascript:void(0)">Mens Fashion</a></li>
                                <li><a href="javascript:void(0)">Kids Fashion</a></li>
                                <li><a href="javascript:void(0)">Featured</a></li> -->
                            </ul>
                        </div>
					</div>
				</div>
				<!-- <div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>why we choose</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)">shipping & return</a></li>
                                <li><a href="javascript:void(0)">secure shopping</a></li>
                                <li><a href="javascript:void(0)">gallary</a></li>
                                <li><a href="javascript:void(0)">affiliates</a></li>
                                <li><a href="javascript:void(0)">contacts</a></li>
                            </ul>
                        </div>
					</div>
				</div> -->
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>{{'Contact Us'|translate}}</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list" *ngIf="ContactUsList && ContactUsList.length > 0">
						        <li><i class="fa fa-map-marker"></i>{{ContactUsList[0].address|translate}}</li>
						        <li><i class="fa fa-phone"></i>{{ ContactUsList[0].phone}}</li>
						        <li><i class="fa fa-envelope-o"></i> <a href = "mailto:{{ContactUsList[0].emailAddress}}">{{ContactUsList[0].emailAddress}}							</a></li>
						        <!-- <li><i class="fa fa-fax"></i>{{'Fax'|translate}}: 123456</li> -->
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- <div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} theme forest powered by pixelstrap</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <ul>
                      <li>
                        <a><img src="assets/images/icon/visa.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/paypal.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/american-express.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/discover.png" alt=""></a>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
		</div>
	</div> -->
</footer>
<!--footer section end -->