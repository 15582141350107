import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivityModel } from 'src/app/Islamic-Center/Activites/ActivityModel';
import { Product } from 'src/app/shared/classes/product';
import { QuickViewComponent } from '../../modal/quick-view/quick-view.component';
import { CartModalComponent } from '../../modal/cart-modal/cart-modal.component';
import { ActivityService } from 'src/app/Islamic-Center/Activites/activity.service';
import { LangService } from 'src/app/shared/lang.service';

@Component({
    // moduleId: module.id,
    selector: 'activites',
    templateUrl: 'activites.component.html',
    styleUrls: ['activites.component.scss']
})
export class ActivitesComponent implements OnInit {

    @Input() product: Product;
    @Input() Activity: ActivityModel;
    // @Input() currency: any = this.productService.Currency; // Default Currency 
    @Input() thumbnail: boolean = false; // Default False 
    @Input() onHowerChangeImage: boolean = false; // Default False
    @Input() cartModal: boolean = false; // Default False
    @Input() loader: boolean = false;
    
    @ViewChild("quickView") QuickView: QuickViewComponent;
    @ViewChild("cartModal") CartModal: CartModalComponent;
  
    public ImageSrc : string
  public lang='';
    constructor(private modelService: ActivityService,public langService:LangService) {
        debugger;
        this.lang= localStorage.getItem('theme_lang');
     }
  
    ngOnInit(): void {
this.modelService.getAll().subscribe(data=>{
    this.ActivityList=data;
    //console.log("WorkshopsList",this.ActivityList)
})
      if(this.loader) {
        setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
      }
    }
  ActivityList:ActivityModel[]=[];
  modelObject:ActivityModel={};
    // Get Product Color
    Color(variants) {
      const uniqColor = [];
      for (let i = 0; i < Object.keys(variants).length; i++) {
        if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
          uniqColor.push(variants[i].color)
        }
      }
      return uniqColor
    }
  
    // Change Variants
    ChangeVariants(color, product) {
      product.variants.map((item) => {
        if (item.color === color) {
          product.images.map((img) => {
            if (img.image_id === item.image_id) {
              this.ImageSrc = img.src;
            }
          })
        }
      })
    }
  
    // Change Variants Image
    ChangeVariantsImage(src) {
      this.ImageSrc = src;
    }
  
    // addToCart(product: any) {
    //   this.modelService.addToCart(product);
    // }
  
    // addToWishlist(product: any) {
    //   this.modelService.addToWishlist(product);
    // }
  
    // addToCompare(product: any) {
    //   this.modelService.addToCompare(product);
    // }
  
  }
  