import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivityTypeModel } from 'src/app/Islamic-Center/Activites/ActivityType/ActivityTypeModel';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
	ActivityType?:ActivityTypeModel[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor() { }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		
		{
			title: 'About Us', type: 'sub', active: false, children: [
				{ path: '/islamic-center/about-us', title: 'About Us', type: 'link' },
				{ path: '/islamic-center/directors', title: 'Board of Directors', type: 'link' },
				{ path: '/islamic-center/managers', title: 'Managers', type: 'link' },
				{ path: '/islamic-center/faculty-members', title: 'Faculty Members', type: 'link' },
				{ path: '/islamic-center/rural-branch', title: 'Rural Branch', type: 'link' },
				
			]
		},
		{
			title: 'International Islamic Institute', type: 'sub', active: false, children: [
				{ path: '/islamic-center/about-institute', title: 'About The Institute', type: 'link' },
				{ path: '/islamic-center/international-islamicIn-stitute-members', title: 'Faculty Members', type: 'link' },
				{ path: '/islamic-center/international-islamicIn-stitute-news', title: 'Institute Activities', type: 'link' },
			]
		},
		{
			title: 'Activities', type: 'sub', active: false, children: [
	
			]
		},

		{
			title: 'ART unit', type: 'sub', active: false, children: [
			
			]
		},
		{
			title: 'Research ethics', type: 'sub', active: false, children: [
			
			]
		},
		{
			title: 'Publications', type: 'sub', active: false, children: [
	
			]
		},
		// {
		// 	path: '/islamic-center/gallery',title: 'gallery', type: 'link'
		// },
		// {
		// 	title: 'Center Library', type: 'sub', active: false, children: [
	
		// 	]
		// },
		{
			path: '/islamic-center/contact',title: 'Contact Us', type: 'link'
		}
	];

	LEFTMENUITEMS: Menu[] = [
		{
			path: '/',title: 'Home', type: 'link'
		},
		{
			title: 'About Us', type: 'sub', active: false, children: [
				{ path: '/islamic-center/about-us', title: 'About Us', type: 'link' },
				{ path: '/islamic-center/managers', title: 'Managers', type: 'link' },
				{ path: '/islamic-center/faculty-members', title: 'Faculty Members', type: 'link' },
				{ path: '/islamic-center/rural-branch', title: 'Rural Branch', type: 'link' },
				
			]
		},
		{
			title: 'International Islamic Institute', type: 'sub', active: false, children: [
				{ path: '/islamic-center/about-institute', title: 'About The Institute', type: 'link' },
				{ path: '/islamic-center/international-islamicIn-stitute-members', title: 'Faculty Members', type: 'link' },
				{ path: '/islamic-center/international-islamicIn-stitute-news', title: 'Institute Activities', type: 'link' },
			]
		},
		{
			title: 'Activities', type: 'sub', active: false, children: [
	
			]
		},

		{
			title: 'ART unit', type: 'sub', active: false, children: [
			
			]
		},
		{
			title: 'Research ethics', type: 'sub', active: false, children: [
			
			]
		},
		{
			title: 'Publications', type: 'sub', active: false, children: [
	
			]
		},
		{
			path: '/islamic-center/gallery',title: 'gallery', type: 'link'
		},
		// {
		// 	title: 'Center Library', type: 'sub', active: false, children: [
	
		// 	]
		// },
		{
			path: '/islamic-center/contact',title: 'Contact Us', type: 'link'
		}
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);
	ActivityType:ActivityTypeModel[]=[];
}
