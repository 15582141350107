import { Component, OnInit, Input, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { ContactsModel } from 'src/app/Islamic-Center/Master-Data/Contacts/ContactsModel';
import { ContactUsService } from 'src/app/Islamic-Center/Master-Data/Contacts/contact-us.service';
import { LangService } from '../../../../app/shared/lang.service';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
  
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/MyImage/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  public stick: boolean = false;
  public languages = [{ 
    name: 'English',
    code: 'en'
  },

  {
    name: 'Arabice',
    code: 'ar-EG'
  }];
  lang='';

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
   private modelService :ContactUsService,
   public LangService : LangService,
   private translate: TranslateService,
   ) {
    this.lang= localStorage.getItem('theme_lang');
    }
  ContactUsList:ContactsModel[]=[];

  ngOnInit(): void {
    this.modelService.getAll().subscribe(data=>{
      this.ContactUsList=data;
  })
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 150 && window.innerWidth > 400) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }
  customizeLayoutDark() {
    document.body.classList.toggle('dark')
  }
  changeLanguage(code:string){
    debugger;
    if(code == 'ar-EG') {
      document.body.classList.remove('ltr')
      document.body.classList.add('rtl')
      // window.location.reload();

    } 
    else {
      document.body.classList.remove('rtl')
      document.body.classList.add('ltr')
      // window.location.reload();
    }
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
      this.LangService.language = code;
      window.location.reload();
    }
  }
}
