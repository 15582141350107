import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ResearchTypeService {

  
  constructor(private http: HttpClient ) { }
  baseUrl = environment.baseApiUrl;
  token = localStorage.getItem("token");
  httpOptions = {
   headers: new HttpHeaders({
     // 'Content-Type': 'application/json; charset=utf-8',
     // 'Authorization': "Bearer " + token,
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
   })
 };

 getAll(): Observable<any> {
   
  return this.http
    .get<any>(`${this.baseUrl}ResearchType/GetAll`,this.httpOptions)
    .pipe(catchError((error: any) => throwError(error => error)));
}


getById(Id:number): Observable<any> {

  return this.http
    .get<any>(`${this.baseUrl}ResearchType/GetById/${Id}`,this.httpOptions)
    .pipe(catchError((error: any) => throwError(error => error)));
}





}
