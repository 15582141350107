<!--component html goes here -->
<ng-template class="theme-modal" #Regrster let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-header">
            <button type="button" class="close float-end border-0" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
         
            <div class="row">
                <form class="theme-form" [formGroup]="form">
                    <div class="row">
                        <div class="col-md-12">
                            <label for="email">{{'Name'|translate}}</label>
                            <input type="text" class="form-control" formControlName="name" id="fname" placeholder="{{'Name'|translate}}" required="">
                            <P style="color: red;" *ngIf="form.controls['name'].hasError('required')&&form.controls['name'].touched"  class="required alert-error">  
                                {{"Name required" | translate}}
                            </P>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label for="email">{{'Phone'|translate}}</label>
                            <input type="text" class="form-control" formControlName="phone" id="fname" placeholder="{{'Phone'|translate}}" required="">
                            <P style="color: red;" *ngIf="form.controls['phone'].hasError('required')&&form.controls['phone'].touched"  class="required alert-error">  
                                {{"Phone required" | translate}}
                            </P>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label for="email">{{'Email'|translate}}</label>
                            <input type="text" class="form-control" formControlName="email" id="email" placeholder="{{'Email'|translate}}" required="">
                            <P style="color: red;" *ngIf="form.controls['email'].hasError('required')&&form.controls['email'].touched"  class="required alert-error">  
                                {{"Email required" | translate}}
                            </P>
                     
                        </div>
                      
                    </div>
                </form>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-solid" [disabled]="form.invalid" (click)="submit()">{{'Send'|translate}}</button>

        </div>
    </div>
</ng-template>