import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
  import { isPlatformBrowser } from '@angular/common';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FacultyMembersService } from 'src/app/Islamic-Center/Master-Data/FacultyMembers/faculty-members.service';
import { FacultyMembersModel } from 'src/app/Islamic-Center/Master-Data/FacultyMembers/FacultyMembersModel';
@Component({
    // moduleId: module.id,
    selector: 'islamic-center-model',
    templateUrl: 'islamic-center-model.component.html',
    styleUrls: ['islamic-center-model.component.scss']
})
export class IslamicCenterModelComponent implements OnInit  {
    declare lang: any;
    @Input() item: FacultyMembersModel;
    @Input() currency: any;  
    @ViewChild("quickView", { static: false }) QuickView: TemplateRef<any>;
  
    public closeResult: string;
    public ImageSrc: string;
    public counter: number = 1;
    public modalOpen: boolean = false;
  
    constructor(@Inject(PLATFORM_ID) private platformId: Object,
      private router: Router, private modalService: NgbModal,
      private FacultyMembersSevice:FacultyMembersService,
      ) {
        this.lang = localStorage.getItem('theme_lang');

       }
      ngOnInit(): void {
    }  
    FacultyMembersList:FacultyMembersModel[]=[];
    openModal() {
        this.modalOpen = true;
        if (isPlatformBrowser(this.platformId)) { // For SSR 
          this.modalService.open(this.QuickView, { 
            size: 'lg',
            ariaLabelledBy: 'modal-basic-title',
            centered: true,
            windowClass: 'Quickview' 
          }).result.then((result) => {
            `Result ${result}`
          }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
        }
      }
      private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return `with: ${reason}`;
        }
      }
}
